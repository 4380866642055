import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const industries = [
  { name: 'Technology', baseCloseRate: 0.25 },
  { name: 'Healthcare', baseCloseRate: 0.20 },
  { name: 'Finance', baseCloseRate: 0.15 },
  { name: 'Retail', baseCloseRate: 0.30 },
  { name: 'Manufacturing', baseCloseRate: 0.18 }
];

const services = [
  { name: 'Fractional CRO', description: 'We audit and optimize your revenue strategy, manage your revenue teams, and provide you with a robust revenue growth plan.' },
  { name: 'Demand Generation & RevOps', description: 'We create and execute demand generation campaigns to keep you top-of-mind with your target audience.' },
  { name: 'Recruitment', description: 'We connect clients with high-impact talent to drive transformation and growth.' },
  { name: 'Technology & Data', description: 'We optimize your technology stack to document leads and touchpoints, enabling better campaigns and clear revenue understanding.' },
];

const founders = [
  {
    name: 'Simon Jalbert',
    title: 'Co-Founder',
    image: '/img_simon.jpeg',
    description: 'Simon is an entrepreneur at heart, having exited two software companies in Silicon Valley and Toronto. He has been advising Bridgestone, Mozilla Firefox, and NRG on growth since 2020.'
  },
  {
    name: 'Jakub Patera',
    title: 'Co-Founder',
    image: '/img_jakub.jpg',
    description: 'Growing and advising businesses all across North America and Europe. Building custom customer acquisition channels with new tech and AI.'
  }
];

const AcquireRevDashboard = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [industry, setIndustry] = useState(industries[0].name);
  const [companyName, setCompanyName] = useState('');
  const [monthlyLeads, setMonthlyLeads] = useState(100);
  const [aov, setAOV] = useState(1000);
  const [graphData, setGraphData] = useState([]);
  const [assumedCloseRate, setAssumedCloseRate] = useState(industries[0].baseCloseRate);

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'k';
    }
    return num.toString();
  };

  const getMonthName = (monthIndex) => {
    const date = new Date();
    date.setMonth(date.getMonth() + monthIndex);
    return date.toLocaleString('default', { month: 'short' });
  };

  useEffect(() => {
    const selectedIndustry = industries.find(ind => ind.name === industry);
    const baseCloseRate = selectedIndustry ? selectedIndustry.baseCloseRate : 0.2;
    setAssumedCloseRate(baseCloseRate);
    const improvedCloseRate = baseCloseRate * 1.1; // 10% increase

    const newGraphData = Array.from({ length: 12 }, (_, i) => {
      const leadsWithoutAcquireRev = monthlyLeads;
      const leadsWithAcquireRev = monthlyLeads * Math.pow(1.5, i / 12); // 50% annual growth

      const revenueWithoutAcquireRev = leadsWithoutAcquireRev * baseCloseRate * aov * (i + 1);
      const revenueWithAcquireRev = leadsWithAcquireRev * improvedCloseRate * aov * (i + 1);

      return {
        month: getMonthName(i),
        withoutAcquireRev: Math.round(revenueWithoutAcquireRev),
        withAcquireRev: Math.round(revenueWithAcquireRev)
      };
    });

    setGraphData(newGraphData);
  }, [industry, monthlyLeads, aov]);

  const renderGraph = () => (
    <div style={{ backgroundColor: 'white', borderRadius: '8px', padding: '16px', marginTop: '24px' }}>
      <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '16px' }}>Revenue Growth Projection</h2>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={graphData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis tickFormatter={(value) => '$' + formatNumber(value)} />
          <Tooltip formatter={(value) => ['$' + formatNumber(value), 'Revenue']} />
          <Legend />
          <Line type="monotone" dataKey="withoutAcquireRev" name="Without AcquireRev" stroke="#9CA3AF" />
          <Line type="monotone" dataKey="withAcquireRev" name="With AcquireRev" stroke="#10B981" />
        </LineChart>
      </ResponsiveContainer>
      <p style={{ marginTop: '16px', textAlign: 'center', fontSize: '0.875rem', color: '#4b5563' }}>
        This chart illustrates the potential revenue growth with and without AcquireRev's strategies.
      </p>
    </div>
  );

  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return (
          <div>
          <div style={{ backgroundColor: 'white', borderRadius: '8px', padding: '16px', marginBottom: '24px' }}>
            <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '16px' }}>Driving growth through Transformational Revenue Leadership</h2>
            <p>We are transformation experts who immerse and own the revenue KPI in your business business, bringing leadership, execution, recruitment, and technology that energizes change from within.            </p>
            </div>
            <div style={{ backgroundColor: 'white', borderRadius: '8px', padding: '16px', marginBottom: '24px' }}>
              <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '16px' }}>How is it to work with us?</h2>
              
              <div>
                <p style={{ fontStyle: 'italic', marginBottom: '8px' }}>"What sets AcquireRev apart is that they truly own the KPIs they are trying to affect. Their focus on sustainable growth and effectively firing themselves over time make them a trusted partner of ours."</p>
                <p style={{ fontWeight: 'bold' }}>- Shobhit Shanker, Owner and CEO, North American Decal</p>
              </div>
            </div>
            {renderGraph()}
          </div>
        );
      case 'services':
        return (
          <div>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '24px', marginBottom: '24px' }}>
              {services.map((service, index) => (
                <div key={index} style={{ backgroundColor: 'white', borderRadius: '8px', padding: '16px' }}>
                  <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '8px' }}>{service.name}</h3>
                  <p>{service.description}</p>
                </div>
              ))}
            </div>
            {renderGraph()}
          </div>
        );
      case 'team':
        return (
          <div>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '24px', marginBottom: '24px' }}>
              {founders.map((founder, index) => (
                <div key={index} style={{ backgroundColor: 'white', borderRadius: '8px', padding: '16px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                    <img src={founder.image} alt={founder.name} style={{ width: '64px', height: '64px', borderRadius: '50%', marginRight: '16px' }} />
                    <div>
                      <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{founder.name}</h3>
                      <p style={{ fontSize: '0.875rem', color: '#4b5563' }}>{founder.title}</p>
                    </div>
                  </div>
                  <p>{founder.description}</p>
                </div>
              ))}
            </div>
            {renderGraph()}
          </div>
        );
      default:
        return null;
    }
  };

  const inputStyle = {
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #d1d5db',
    backgroundColor: '#fafafa',
    marginBottom: '8px',
    width: '100%'
  };

  return (
    <div style={{ padding: '24px', maxWidth: '1200px', margin: '0 auto', backgroundColor: '#f3f4f6' }}>
      <header style={{ marginBottom: '32px', textAlign: 'center' }}>
      <h1 className="text-3xl font-bold mb-6">
        {companyName ? `${companyName} Dashboard` : 'AcquireRev Dashboard'}
      </h1>
        <p style={{ fontSize: '1.25rem', color: '#4b5563' }}>Fast, simple revenue optimization solutions for sustainable growth</p>
      </header>

      <div style={{ backgroundColor: '#fafafa', borderRadius: '8px', padding: '16px', marginBottom: '24px' }}>
        <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '16px' }}>Fill these fields and we'll tell you what you can expect</h2>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))', gap: '16px' }}>
          <div>
            <label htmlFor="companyName" style={{ display: 'block', marginBottom: '4px' }}>Company Name: </label>
            <input
              id="companyName"
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              style={inputStyle}
            />
          </div>
          <div>
            <label htmlFor="industry" style={{ display: 'block', marginBottom: '4px' }}>Industry: </label>
            <select id="industry" value={industry} onChange={(e) => setIndustry(e.target.value)} style={inputStyle}>
              {industries.map((ind) => (
                <option key={ind.name} value={ind.name}>{ind.name}</option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="monthlyLeads" style={{ display: 'block', marginBottom: '4px' }}>Enter your current Monthly Leads: </label>
            <input
              id="monthlyLeads"
              type="number"
              value={monthlyLeads}
              onChange={(e) => setMonthlyLeads(Number(e.target.value))}
              min="1"
              style={inputStyle}
            />
          </div>
          <div>
            <label htmlFor="aov" style={{ display: 'block', marginBottom: '4px' }}>Enter your Average Order Value $</label>
            <input
              id="aov"
              type="number"
              value={aov}
              onChange={(e) => setAOV(Number(e.target.value))}
              min="1"
              style={inputStyle}
            />
          </div>
        </div>
        <p style={{ marginTop: '16px' }}>Assumed Close Rate: {(assumedCloseRate * 100).toFixed(1)}%</p>
      </div>

      <div style={{ marginBottom: '24px' }}>
        {['overview', 'services', 'team'].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            style={{
              padding: '8px 16px',
              backgroundColor: activeTab === tab ? '#3b82f6' : '#e5e7eb',
              color: activeTab === tab ? 'white' : 'black',
              border: 'none',
              borderRadius: '4px',
              marginRight: '8px'
            }}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>

      {renderContent()}

      <div style={{ textAlign: 'center', marginTop: '32px' }}>
        <a 
          href="https://calendly.com/your-link-here" 
          target="_blank" 
          rel="noopener noreferrer"
          style={{
            display: 'inline-block',
            padding: '16px 32px',
            backgroundColor: '#10B981',
            color: 'white',
            fontSize: '1.25rem',
            fontWeight: 'bold',
            textDecoration: 'none',
            borderRadius: '8px',
            transition: 'background-color 0.3s'
          }}
          onMouseOver={(e) => e.target.style.backgroundColor = '#059669'}
          onMouseOut={(e) => e.target.style.backgroundColor = '#10B981'}
        >
          Schedule a Free Consultation
        </a>
      </div>
    </div>
  );
};

export default AcquireRevDashboard;